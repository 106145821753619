// import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  loggedCheckBegin,
  loggedCheckSuccess,
  loggedCheckErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/tokens/create', values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        // Cookies.set('user_data', JSON.stringify(response.data.result.user));
        // Cookies.set('access_token', response.data.result.access_token);
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const loggedCheck = (values) => {
  return async (dispatch) => {
    dispatch(loggedCheckBegin());
    try {
      const response = await DataService.get('/check', values);
      if (response.data.error) {
        dispatch(loggedCheckErr(response.data.errors));
      } else {
        dispatch(loggedCheckSuccess(true));
        // callback();
      }
    } catch (err) {
      dispatch(loggedCheckErr(err));
    }
  };
};

const register = () => {};

// const register = (values) => {
//   return async (dispatch) => {
//     dispatch(loginBegin());
//     try {
//       const response = await DataService.post('/register', values);
//       if (response.data.errors) {
//         dispatch(loginErr('Registration failed!'));
//       } else {
//         dispatch(loginSuccess(false));
//       }
//     } catch (err) {
//       dispatch(loginErr(err));
//     }
//   };
// };

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      // Cookies.remove('access_token');
      // Cookies.remove('user_data');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, loggedCheck };
