import actions from './actions';

const {
  // GET PROJECTS
  PROJECTS_READ_BEGIN,
  PROJECTS_READ_SUCCESS,
  PROJECTSE_READ_ERR,
} = actions;

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const projectsReducers = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PROJECTS_READ_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case PROJECTS_READ_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        error: false,
        loading: false,
      };

    case PROJECTSE_READ_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { projectsReducers };
