import _ from 'lodash';
import actions from './actions';

const {
  // GET
  USER_TOKENS_READ_BEGIN,
  USER_TOKENS_READ_SUCCESS,
  USER_TOKENS_READ_ERR,
  // UPDATE
  //   USER_TOKENS_UPDATE_BEGIN,
  USER_TOKENS_UPDATE_SUCCESS,
  //   USER_TOKENS_UPDATE_ERR,
} = actions;

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const userTokensReducers = (state = initialState, action) => {
  const { type, data, err } = action;
  let stateData;
  switch (type) {
    case USER_TOKENS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_TOKENS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case USER_TOKENS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_TOKENS_UPDATE_SUCCESS:
      // Podmiana klucza w obiekcie
      if (state.data) {
        stateData = _.map(state.data, (item) => {
          if (item.app_name === action.data.app_name) {
            return action.data;
          }
          return item;
        });
      }

      return {
        ...state,
        data: stateData,
        error: false,
        loading: false,
      };

    default:
      return state;
  }
};

export { userTokensReducers };
