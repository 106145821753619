const actions = {
  ISSUE_READ_BEGIN: 'ISSUE_READ_BEGIN',
  ISSUE_READ_SUCCESS: 'ISSUE_READ_SUCCESS',
  ISSUE_READ_ERR: 'ISSUE_READ_ERR',

  ISSUE_ASSIGN_BEGIN: 'ISSUE_ASSIGN_BEGIN',
  ISSUE_ASSIGN_SUCCESS: 'ISSUE_ASSIGN_SUCCESS',
  ISSUE_ASSIGN_ERR: 'ISSUE_ASSIGN_ERR',

  issueReadBegin: () => {
    return {
      type: actions.ISSUE_READ_BEGIN,
    };
  },

  issueReadSuccess: (data) => {
    return {
      type: actions.ISSUE_READ_SUCCESS,
      data,
    };
  },

  issueReadErr: (err) => {
    return {
      type: actions.ISSUE_READ_ERR,
      err,
    };
  },

  issueAssignBegin: () => {
    return {
      type: actions.ISSUE_ASSIGN_BEGIN,
    };
  },

  issueAssignSuccess: (data) => {
    return {
      type: actions.ISSUE_ASSIGN_SUCCESS,
      data,
    };
  },

  issueAssignErr: (err) => {
    return {
      type: actions.ISSUE_ASSIGN_ERR,
      err,
    };
  },
};

export default actions;
