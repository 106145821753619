import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const IssueManagement = lazy(() => import('../../container/pages/IssueManage'));

function IssuesRoutes() {
  return (
    <Routes>
      <Route path="issue-manage" element={<IssueManagement />} />
    </Routes>
  );
}

export default IssuesRoutes;
