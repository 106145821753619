const actions = {
  PROJECTS_READ_BEGIN: 'PROJECTS_READ_BEGIN',
  PROJECTS_READ_SUCCESS: 'PROJECTS_READ_SUCCESS',
  PROJECTSE_READ_ERR: 'PROJECTSE_READ_ERR',

  projectsReadBegin: () => {
    return {
      type: actions.PROJECTS_READ_BEGIN,
    };
  },

  projectsReadSuccess: (data) => {
    return {
      type: actions.PROJECTS_READ_SUCCESS,
      data,
    };
  },

  projectsReadErr: (err) => {
    return {
      type: actions.PROJECTSE_READ_ERR,
      err,
    };
  },
};

export default actions;
