const actions = {
  USER_TOKENS_READ_BEGIN: 'USER_TOKENS_READ_BEGIN',
  USER_TOKENS_READ_SUCCESS: 'USER_TOKENS_READ_SUCCESS',
  USER_TOKENS_READ_ERR: 'USER_TOKENS_READ_ERR',

  USER_TOKENS_UPDATE_BEGIN: 'USER_TOKENS_UPDATE_BEGIN',
  USER_TOKENS_UPDATE_SUCCESS: 'USER_TOKENS_UPDATE_SUCCESS',
  USER_TOKENS_UPDATE_ERR: 'USER_TOKENS_UPDATE_ERR',

  userTokensReadBegin: () => {
    return {
      type: actions.USER_TOKENS_READ_BEGIN,
    };
  },

  userTokensReadSuccess: (data) => {
    return {
      type: actions.USER_TOKENS_READ_SUCCESS,
      data,
    };
  },

  userTokensReadErr: (err) => {
    return {
      type: actions.USER_TOKENS_READ_ERR,
      err,
    };
  },

  userTokensUpdateBegin: () => {
    return {
      type: actions.USER_TOKENS_UPDATE_BEGIN,
    };
  },

  userTokensUpdateSuccess: (data) => {
    return {
      type: actions.USER_TOKENS_UPDATE_SUCCESS,
      data,
    };
  },

  userTokensUpdateErr: (err) => {
    return {
      type: actions.USER_TOKENS_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
